
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import { processErrors } from "@/api/errorsProcessing";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface Data {
  items: [];
  keyword: string;
  pagination: Pagination;
  orderBy: string;
}
export default defineComponent({
  name: "PatientListPage",
  components: {},
  setup() {
    const url = "api/v1/patients";
    const router = useRouter();
    //Account Id	First Name	Middle Name	Last Name	DOB
    const orderList = [
      { name: "Last Name", key: "lastName" },
      { name: "First Name", key: "firstName" },
      { name: "Account Id", key: "accountId" },
      { name: "DOB", key: "dob" },
    ];

    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      items: [],
      keyword: "",
      orderBy: "lastName",
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 10,
      },
    });

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Patient Accounts");
      await getAll();
    });

    async function search() {
      await getAll();
    }

    async function changePage(index) {
      if (data.pagination.currentPage == 1 && index < 0) return;
      if (
        data.pagination.currentPage == data.pagination.totalPages &&
        index > 0
      )
        return;

      data.pagination.currentPage = data.pagination.currentPage + index;
      await getAll();
    }

    async function selectPage(index) {
      data.pagination.currentPage = index;
      await getAll();
    }

    async function getAll() {
      let request = {
        advancedSearch: {
          keyword: data.keyword,
          fields: ["firstName", "lastName", "accountId", "middleName", "dob"],
        },
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: [data.orderBy],
      };
      await ApiService.post(url + "/search", request as never)
        .then((res) => {
          data.items = res.data.data;
          data.pagination.currentPage = res.data.currentPage;
          data.pagination.totalPages = res.data.totalPages;
          data.pagination.totalCount = res.data.totalCount;
          data.pagination.pageSize = res.data.pageSize;
          //TODO DEBT
          data.items.forEach((item: any) => {
            item.dob = new Date(item.dob);
          });
        })
        .catch(({ response }) => {
          processErrors(response);
        });
    }

    function selectItem(item) {
      router.push({ name: "patientPage", params: { patientId: item.id } });
    }

    async function cleanFilter() {
      data.keyword = "";
      data.orderBy = "lastName";
      await getAll();
    }

    return {
      organizationId,
      data,
      selectItem,
      orderList,
      selectPage,
      search,
      cleanFilter,
      changePage,
    };
  },
});
